import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SearchLogItemType, SearchTileType, SearchTileTypeResponse } from '@backend/types';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import './App.css';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import Map from './features/map/Map';
import Panel from './features/panel/Panel';
import { ApiService } from './service';
import { FetchTilesRequestType } from './types';

const searchMap: { [referenceId: string]: SearchTileType } = {}

const App = () => {

  const [tileRequest, setTileRequest] = useState<FetchTilesRequestType | 'all'>()
  const [selectedTiles, setSelectedTiles] = useState<SearchTileTypeResponse[]>()
  const [searches, setSearches] = useState<SearchLogItemType[]>([])

  const fetchTiles = async () => {

    const service = await ApiService()

    const searches = await service.fetchSearches()
    const searchIds: string[] = searches.map(s => [s.fromTile, s.toTile]).reduce((prev, curr) => [...prev, ...curr]);

    const ids = Array.from(new Set(searchIds).values());

    const areas = await service.fetchAreas(ids)
    areas.forEach(area => searchMap[area.referenceId] = area)
    await setSearches(searches)
  }

  const setAllTiles = () => {
    setSelectedTiles(searches.map(search => ({
      count: search.count,
      startTile: searchMap[search.fromTile],
      endTile: searchMap[search.toTile]
    })))
  }

  useEffect(() => {
    fetchTiles()
  }, [])

  useEffect(() => {

    if (!tileRequest) return

    if (tileRequest !== 'all') {
      setSelectedTiles([{
        startTile: searchMap[tileRequest!.startTile],
        endTile: searchMap[tileRequest!.endTile]
      }])
    } else {
      setAllTiles()
    }
  }, [tileRequest])

  return (
    <>
      <AuthenticatedTemplate>
        <Box width={'auto'} height={'auto'}>
          <Grid container sx={{ minHeight: '100%' }}>
            <Grid xs={10}>
              <Map selectedTiles={selectedTiles} />
            </Grid>
            <Grid xs={2}>
              <Panel setTileRequest={setTileRequest} searches={searches} />
            </Grid>
          </Grid>
        </Box>
        <div id="signout-button"><SignOutButton /></div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate><SignInButton /></UnauthenticatedTemplate>
    </>
  )
}

export default App;
