import { SearchTileTypeResponse } from "@backend/types";
import mapboxgl, { Map as MapType } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { BoundingBoxType } from "../../types";
import { drawFeatures } from "./functions";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TURKU_BB: BoundingBoxType = {
    bottomLeft: {
        longitude: 21.982023629595407,
        latitude: 60.341236305171975
    },
    topRight: {
        longitude: 22.572031135793026,
        latitude: 60.70025546383697
    }
}

type MapPropsType = {
    selectedTiles?: SearchTileTypeResponse[]
}

const Map = ({ selectedTiles }: MapPropsType) => {

    const mapContainer = useRef<any>(null);
    const map = useRef<MapType | null>(null);

    useEffect(() => {
        if (!selectedTiles) return
        drawFeatures(map.current!)(selectedTiles)
    }, [ selectedTiles ])

    useEffect(() => {

        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/tuomaspiippo/clfl5138b00aq01s27zl7uvi7',
            accessToken: 'pk.eyJ1IjoidHVvbWFzcGlpcHBvIiwiYSI6ImNrMWh3MDhvODBwamUzY3Fwdmlnd3dxdTkifQ.Sk2raQZ8xa45hH0H4YC_1Q',
            zoom: 9,
        })

        map.current.on('load', () => {

            map.current!.fitBounds([
                [TURKU_BB.bottomLeft.longitude, TURKU_BB.bottomLeft.latitude],
                [TURKU_BB.topRight.longitude, TURKU_BB.topRight.latitude]
            ])
        })
    }, [])

    return (<div ref={mapContainer} style={{ height: "100vh" }} className="map-container"></div>)
}

export default Map