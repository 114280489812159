import { SearchTileTypeResponse } from "@backend/types";
import { Map as MapType } from "mapbox-gl";

const currentSources: string[] = []
const currentLayers: string[] = []

export const drawFeatures = (map: MapType) => (tiles: SearchTileTypeResponse[]): void => {

    currentLayers.forEach(layer => { map.removeLayer(layer) })
    currentLayers.splice(0, currentLayers.length)
    currentSources.forEach(source => { map.removeSource(source) })
    currentSources.splice(0, currentSources.length)

    tiles.forEach((tile, idx) => {

        map.addSource(`tilesource_${idx}`, {
            type: 'geojson',
            data: {
                'type': 'FeatureCollection',
                'features': [{
                    'type': 'Feature',
                    'geometry': tile.startTile.geometry as any,
                    'properties': {
                        centroid: tile.startTile.centroid
                    }
                }, {
                    'type': 'Feature',
                    'geometry': tile.endTile.geometry as any,
                    properties: {
                        centroid: tile.startTile.centroid
                    }
                }]
            }
        })

        currentSources.push(`tilesource_${idx}`)

        map.addSource(`linesource_${idx}`, {
            type: 'geojson',
            data: {
                'type': 'Feature',
                'geometry': {
                    type: 'LineString',
                    coordinates: [
                        (tile.startTile.centroid! as any).coordinates,
                        (tile.endTile.centroid! as any).coordinates,
                    ]
                },
                'properties': {
                }
            }
        })

        currentSources.push(`linesource_${idx}`)

        map.addLayer({
            id: `tilefilllayer_${idx}`,
            type: 'fill',
            source: `tilesource_${idx}`,
            'paint': {
                'fill-color': 'blue',
                'fill-opacity': 0.4
            }
        })

        currentLayers.push(`tilefilllayer_${idx}`)

        map.addLayer({
            id: `tilelinelayer_${idx}`,
            type: 'line',
            source: `linesource_${idx}`,
            'paint': {
                'line-color': 'blue',
                'line-width': 1
            }
        })

        currentLayers.push(`tilelinelayer_${idx}`)
    })

}