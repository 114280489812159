import { PublicClientApplication } from '@azure/msal-browser'
import { SearchLogItemType, SearchTileType, SearchTileTypeResponse } from '@backend/types'
import axios from 'axios'
import { msalConfig } from './authConfig'
import { AdminApiServiceType, FetchTilesRequestType } from './types'

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT + '/api'

export function ApiService(): AdminApiServiceType {

    const axiosInstance = axios.create()

    axiosInstance.interceptors.request.use(async function (config) {

      const instance = new PublicClientApplication(msalConfig);
      const activeAccount = instance.getActiveAccount()
      const accounts = instance.getAllAccounts()
  
      if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
      }
  
      const tokenRequest = {
        scopes: ['api://2d038820-e2a5-4523-ba45-b45223b2ee29/access_as_user'],
        account: activeAccount || accounts[0]
      };
  
      let response = null
      try {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        response = await instance.acquireTokenSilent(tokenRequest)
      } catch (e) {
        response = await instance.acquireTokenPopup(tokenRequest)
      }
  
      if (config.headers) {
        config.headers['Authorization'] = `Bearer ${response.accessToken}`
      }
  
      return config
    });
      
    return {
        fetchSearches: async (): Promise<SearchLogItemType[]> => {
            return (await axiosInstance.get<SearchLogItemType[]>(`${ENDPOINT}/admin`)).data
        },
        fetchTiles: async (tiles:FetchTilesRequestType): Promise<SearchTileTypeResponse> => {
            return (await axiosInstance.get<SearchTileTypeResponse>(`${ENDPOINT}/admin/fetchtiles/${tiles.startTile}/${tiles.endTile}`)).data
        },
        fetchAreas: async (ids:string[]): Promise<SearchTileType[]> => {
            return (await axiosInstance.get<any>(`${ENDPOINT}/admin/fetchareas?ids=${ids.join(',')}`)).data
        }
    }
}