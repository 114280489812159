import { SearchLogItemType } from "@backend/types";
import { Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FetchTilesRequestType } from "../../types";

type PanelPropsType = {
    setTileRequest: (tiles: FetchTilesRequestType | 'all') => void,
    searches: SearchLogItemType[],
}

const Panel = ({ setTileRequest, searches }: PanelPropsType) => {

    return (
        <Grid container>
            <Grid container xs={12} key={-1} textAlign={'right'}>
                <Grid xs={12}><Button onClick={() => setTileRequest('all')}>Näytä kaikki</Button></Grid>
            </Grid>
            {
                searches.map((s, idx) => (
                    <Grid container xs={12} key={idx}>
                        <Grid xs={8}>{s.count}</Grid>
                        <Grid xs={4}><Button onClick={() => setTileRequest({ count: s.count, startTile: s.fromTile, endTile: s.toTile })}>Näytä</Button></Grid>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default Panel